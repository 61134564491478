import {
  api,
  IImageUploadResponse,
  ImageType,
  ITableData,
  ITableRequest,
  OrganisationFormat,
  OrgRole,
  ReportMetric,
  Status,
  stripEmptyFromObject,
} from '@one-tree/library'
import axios from 'axios'
import {
  ReportingSummaryCurrency,
  ReportingSummaryInterval,
  ITokenPair,
  Resource,
  IReportingSummaryData,
  IReportingMiniGraphData,
  MiniGraphMetricType,
  RankingsMetricType,
  IReportingRankingsData,
  IGlobalSettings,
  IReportingOverviewData,
} from '../types/APItypes'
import {
  IAdminCards,
  IAdminPending,
  IDispatch,
  IInvoice,
  IInvoiceAddition,
  InvoiceStatus,
} from '../types/adminTypes'
import {
  IAdminOrg,
  IGroup,
  IHostedPage,
  IPerformance,
  ITypes,
  PartialAdminOrg,
  PartialHosted,
} from '../types/orgTypes'
import { IUser, PartialUser, TableUser } from '../types/userTypes'

const {
  Authenticate,
  RequestPassword,
  ChangePassword,
  RefreshToken,
  Sales,
  Pending,
  Organisations,
  Performance,
  ReportingOverview,
  ReportingSummary,
  MiniGraphs,
  Rankings,
  GlobalSettings,
  Upload,
  Invoices,
  Users,
  Dispatch,
  Masquerade,
  Groups,
  Types,
} = Resource
const { OK, Created } = Status

export const authenticate = async (data: {
  email: string
  password: string
}): Promise<ITokenPair | false> => api(axios.post(Authenticate, data), Created)

export const getRefreshToken = async (data: {
  refreshToken: string
}): Promise<ITokenPair | false> => api(axios.post(RefreshToken, data), Created)

export const requestPassword = async (data: {
  email: string
}): Promise<Boolean> => api(axios.post(RequestPassword, data), OK)

export const changePassword = async (data: {
  hash: string
  password: string
  passwordRepeat: string
}): Promise<Boolean> => api(axios.post(ChangePassword, data), OK)

export const getSales = async (
  data: ITableRequest<IAdminCards>,
): Promise<ITableData<IAdminCards>> => {
  const params = stripEmptyFromObject(data)
  const { slug } = data
  const url = slug ? `${Sales}/${slug}` : Sales
  const request = axios.get(url, { params })
  return api(request, OK)
}

export const getPending = async (
  data: ITableRequest<IAdminPending>,
): Promise<ITableData<IAdminPending>> => {
  const params = stripEmptyFromObject(data)
  const request = axios.get(Pending, { params })
  return api(request, OK)
}

export const getOrganisations = async (): Promise<IAdminOrg[]> => {
  const request = axios.get(Organisations)
  const res = await api(request, OK)
  // paginated endpoint, returns all results though
  return res.data
}

export const getTypes = async (): Promise<ITypes[]> => {
  const request = axios.get(Types)
  const res = await api(request, OK)
  return res
}

export const getPaginatedOrganisations = async (
  data: ITableRequest<IAdminOrg>,
): Promise<ITableData<IAdminOrg>> => {
  const params = stripEmptyFromObject(data)
  const request = axios.get(Organisations, { params })
  return api(request, OK)
}

export const getPerformance = async (
  data: ITableRequest<IPerformance>,
): Promise<ITableData<IPerformance>> => {
  const params = stripEmptyFromObject(data)
  const request = axios.get(Performance, { params })
  return api(request, OK)
}

export const getReportingOverview = async (data: {
  fromMonth: number
  fromYear: number
  format?: OrganisationFormat
  organisationId?: number
  metric?: ReportMetric
}): Promise<IReportingOverviewData | false> => {
  const request = axios.get(ReportingOverview, { params: data })
  return api(request, OK)
}
export const getReportingSummary = async (data: {
  currency: ReportingSummaryCurrency
  interval: ReportingSummaryInterval
}): Promise<IReportingSummaryData | false> => {
  const request = axios.get(ReportingSummary, { params: data })
  return api(request, OK)
}

export const getReportingMiniGraphs = async (data: {
  dataType: MiniGraphMetricType
  fromDate: number
  toDate: number
}): Promise<IReportingMiniGraphData | false> => {
  const request = axios.get(MiniGraphs, { params: data })
  return api(request, OK)
}

export const getReportingRankings = async (data: {
  dataType: RankingsMetricType
  dateFrom: number
  dateTo: number
  format?: OrganisationFormat
  limit: number
}): Promise<IReportingRankingsData | false> => {
  const request = axios.get(Rankings, { params: data })
  return api(request, OK)
}

export const getGlobalSettings = async (): Promise<IGlobalSettings | false> => {
  const request = axios.get(GlobalSettings)
  return api(request, OK)
}

export const patchGlobalSettings = async (
  data: Partial<IGlobalSettings>,
): Promise<IGlobalSettings | false> => {
  const request = axios.patch(GlobalSettings, data)
  return api(request, OK)
}

export const getOrganisation = async (data: {
  id: string
}): Promise<IAdminOrg | false> => {
  const request = axios.get(`${Organisations}/${data.id}`)
  return api(request, OK)
}

export const patchOrganisation = async (data: {
  id: number
  options: PartialAdminOrg
}): Promise<IAdminOrg | false> => {
  const request = axios.patch(`${Organisations}/${data.id}`, data.options)
  return api(request, OK)
}

export const cloneOrganisation = async (data: {
  id: number
  name: string
  format: OrganisationFormat
}): Promise<IAdminOrg | false> => {
  const { id, name, format } = data
  const request = axios.post(`${Organisations}/${id}/clone`, { name, format })
  return api(request, OK)
}

export const createOrganisation = async (
  data: PartialAdminOrg,
): Promise<IAdminOrg | false> => {
  const request = axios.post(Organisations, data)
  return api(request, OK)
}

export const deleteOrganisation = async (data: {
  id: number
}): Promise<string | false> => {
  const request = axios.delete(`${Organisations}/${data.id}`)
  return api(request, OK)
}

export const uploadImage = async (data: {
  image: Blob
  imageType: ImageType
}): Promise<IImageUploadResponse | false> => {
  const formData = new FormData()
  formData.append('upload', data.image)
  formData.append('imageType', data.imageType)

  const request = axios.post(Upload, formData)
  return api(request, Created)
}

export const getInvoices = async (
  data: ITableRequest<IInvoice>,
): Promise<ITableData<IInvoice>> => {
  const params = stripEmptyFromObject(data)
  const { slug } = data
  const url = slug ? `${Organisations}/${slug}/licence` : `${Invoices}/licence`
  const request = axios.get(url, { params })
  return api(request, OK)
}

export const getInvoice = async (data: {
  orgId: string
  id: string
}): Promise<IInvoice | false> => {
  const request = axios.get(`${Organisations}/${data.orgId}/licence/${data.id}`)
  return api(request, OK)
}

export const patchInvoice = async (data: {
  orgId: string
  id: string
  status: InvoiceStatus
}): Promise<IInvoice | false> => {
  const { status } = data
  const request = axios.patch(
    `${Organisations}/${data.orgId}/licence/${data.id}`,
    { status },
  )
  return api(request, OK)
}

export const addToInvoice = async (data: {
  id: number
  addition: IInvoiceAddition
}): Promise<IInvoiceAddition | false> => {
  const request = axios.post(
    `${Organisations}/${data.id}/licence`,
    data.addition,
  )
  return api(request, OK)
}

export const getInvoiceManual = async (data: {
  orgId: number
}): Promise<IInvoiceAddition[] | false> => {
  const request = axios.get(
    `${Organisations}/${data.orgId}/licence/manual-items`,
  )
  return api(request, OK)
}

export const getUsers = async (
  data: ITableRequest<TableUser>,
): Promise<ITableData<TableUser>> => {
  const params = stripEmptyFromObject(data)
  const { slug } = data
  const url = slug ? `${Users}/${slug}` : Users
  const request = axios.get(url, { params })
  return api(request, OK)
}

export const getUser = async (data: { id: string }): Promise<IUser | false> => {
  const request = axios.get(`${Users}/${data.id}`)
  return api(request, OK)
}

export const deleteUser = async (data: {
  id: string
}): Promise<string | false> => {
  const request = axios.delete(`${Users}/${data.id}`)
  return api(request, OK)
}

export const getToken = async (data: {
  id: string
}): Promise<ITokenPair | false> => {
  const request = axios.get(`${Masquerade}/${data.id}`)
  return api(request, Created)
}

export const getDispatch = async (
  data: ITableRequest<IDispatch>,
): Promise<ITableData<IDispatch>> => {
  const params = stripEmptyFromObject(data)
  const request = axios.get(Dispatch, { params })
  return api(request, OK)
}

export const addVoucherToDispatch = async (data: {
  voucherId: string
}): Promise<boolean> => {
  const request = axios.post(`${Dispatch}/add`, data)
  return api(request, OK)
}

export const markVouchersDispatched = async (data: {
  voucherIds: number[]
}): Promise<boolean> => {
  const request = axios.patch(`${Dispatch}`, { dispatches: data.voucherIds })
  return api(request, OK)
}

export const getVoucherMaterials = async (data: {
  export: number[]
}): Promise<{ filename: string } | false> => {
  const request = axios.post(`${Dispatch}/export`, data)
  return api(request, OK)
}

export const addPendingPayment = async (data: {
  voucherId: number
  paymentReference: string
}): Promise<false> => {
  const { voucherId, paymentReference } = data
  const request = axios.post(`${Pending}/${voucherId}`, {
    reference: paymentReference,
  })
  return api(request, Created)
}

export const patchUser = async (data: {
  id: number
  options: PartialUser
}): Promise<IUser | false> => {
  const request = axios.patch(`${Users}/${data.id}`, data.options)
  return api(request, OK)
}

export const deleteOrgFromUser = async (data: {
  orgId: number
  userId: number
}): Promise<IUser | false> => {
  const request = axios.delete(
    `${Users}/${data.userId}/remove-organisation/${data.orgId}`,
  )
  return api(request, OK)
}

export const createUser = async (data: PartialUser): Promise<IUser | false> => {
  const request = axios.post(Users, data)
  return api(request, Created)
}

export const addOrgToUser = async (data: {
  userId: number
  id: number
  role: OrgRole
}): Promise<IUser | false> => {
  const request = axios.post(`${Users}/${data.userId}/add-organisation`, data)
  return api(request, Created)
}

export const getGroups = async (
  data: ITableRequest<IGroup>,
): Promise<ITableData<IGroup>> => {
  const params = stripEmptyFromObject(data)
  const request = axios.get(Groups, { params })
  return api(request, OK)
}

export const createGroup = async (
  data: Partial<IGroup>,
): Promise<IGroup | false> => {
  const request = axios.post(Groups, data)
  return api(request, OK)
}

export const patchGroup = async (
  data: Partial<IGroup>,
): Promise<IGroup | false> => {
  const request = axios.patch(`${Groups}/${data.id}`, data)
  return api(request, OK)
}

export const deleteGroup = async (data: {
  id: number
}): Promise<string | false> => {
  const request = axios.delete(`${Groups}/${data.id}`)
  return api(request, OK)
}

export const exportInvoices = async (data: {
  fromDate?: number
  toDate?: number
  orderBy?: string
  orderDirection?: string
  status?: string
}): Promise<void | false> => {
  const params = stripEmptyFromObject(data)
  const request = axios.get(`${Invoices}/export`, { params })
  return api(request, OK)
}

export const exportOrganisations = async (data: {
  search?: string
  orderBy?: string
  orderDirection?: string
  status?: string
  type?: string
  group?: string
  format?: string
}): Promise<void | false> => {
  const params = stripEmptyFromObject(data)
  const request = axios.get(`${Organisations}/export`, { params })
  return api(request, OK)
}

export const exportUsers = async (data: {
  search?: string
  orderBy?: string
  orderDirection?: string
  status?: string
  organisation?: string
  group?: string
}): Promise<void | false> => {
  const params = stripEmptyFromObject(data)
  const request = axios.get(`${Users}/export`, { params })
  return api(request, OK)
}

export const exportPending = async (data: {
  orderBy?: string
  orderDirection?: string
}): Promise<void | false> => {
  const params = stripEmptyFromObject(data)
  const request = axios.get(`${Pending}/export`, { params })
  return api(request, OK)
}

export const exportPerformance = async (data: {
  search?: string
  orderBy?: string
  orderDirection?: string
  status?: string
  type?: string
  group?: string
  format?: string
}): Promise<void | false> => {
  const params = stripEmptyFromObject(data)
  const request = axios.get(`${Performance}/export`, { params })
  return api(request, OK)
}

export const getOrgAccessToken = async (data: {
  orgId: string
}): Promise<string | false> => {
  const request = axios.post(`${Organisations}/${data.orgId}/access-token`)
  const { token } = await api(request, Created)
  return token
}

export const sendWelcomeEmail = async (data: {
  id: string
}): Promise<string | false> => {
  const request = axios.post(`${Users}/${data.id}/welcome-email`)
  return api(request, OK)
}

export const getHostedPage = async (data: {
  id: string
}): Promise<IHostedPage | false> => {
  const request = axios.get(`${Organisations}/${data.id}/hosted-page`)
  return api(request, OK)
}

export const patchHostedPage = async (data: {
  id: number
  options: PartialHosted
}): Promise<IHostedPage | false> => {
  const request = axios.patch(
    `${Organisations}/${data.id}/hosted-page`,
    data.options,
  )
  return api(request, OK)
}
