import { AdminRole, OrgRole } from '@one-tree/library'

export enum UserStatus {
  Active = 'active',
  Inactive = 'inactive',
  Deleted = 'deleted',
}
export interface IUserOrg {
  id: number
  role: OrgRole
  name?: string
  firstName?: string
  lastName?: string
  status?: UserStatus
  lastLogin?: number
}
export interface IUser {
  userId: number
  emailAddress: string
  title?: string
  name?: string
  firstName?: string
  lastName?: string
  jobTitle?: string
  status: UserStatus
  organisations: IUserOrg[]
  lastLogin?: number
  adminType?: AdminRole | null
  group?: string
  welcomeEmailSent?: number
}

export type PartialUser = Partial<IUser>

export type TableUser = Omit<IUser, 'organisations'> & { organisation?: string }
